<template>
  <div class="bg-grey-4">
    <div class="q-pa-md">
      <message-default-component
        :data="{
          title: $t('account.analyzing_data'),
          message: $t('account.perfil_analyzing'),
        }"
      >
        <q-spinner-ball color="grey" size="10em" />
      </message-default-component>
    </div>
  </div>
</template>

<script>
import MessageDefaultComponent from "./components/MessageDefaultComponent.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PartnerInReviewPage",

  components: {
    MessageDefaultComponent,
  },
});
</script>
